@use '../node_modules/devextreme/scss/widgets/material/colors' as dx with (
  $color: 'blue',
  $mode: 'light'
);

@font-face {
  font-family: 'CzIconFont';
  src: url('assets/fonts/czIconFont.ttf') format('truetype');
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

.dx-viewport .dx-widget {
  font-size: inherit;
}

.dx-theme-material-typography {
  font-size: 20px;
  font-family: 'Roboto', 'RobotoFallback', 'Helvetica', 'Arial', 'sans-serif';
}

.dx-menu-item {
  color: inherit !important;
}

.dx-menu .dx-menu-item {
  font-size: inherit !important;
}

.dx-layout-manager .dx-field-item {
  font-size: inherit !important;
}

.dx-box-item-content {
  font-size: inherit !important;
}

.dx-texteditor-input {
  height: 40px !important;
  font-size: 13px !important;
}

.dx-checkbox-checked .dx-checkbox-icon {
  color: var(--button-menue-font-color-custom);
  background-color: var(--prime-color-darkCustom);
}

// --- dx-popup ---

.dx-popup-content {
  padding: 0;
}

// --- dx-buttons --- NORMAL
.dx-button.dx-button-normal {
  background-color: var(--button-normal-color);
  color: var(--prime-color-white);

  .dx-icon {
    color: var(--prime-color-darkCustom);
  }
}

.dx-button-has-icon {
  .dx-icon {
    font-size: 18px !important;
  }
}

.dx-button.dx-button-normal.dx-state-hover {
  background-color: var(--button-normal-background-color) !important;
  opacity: 0.8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button.dx-button-normal.dx-state-active {
  background-color: var(--button-normal-background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button.dx-button-normal.dx-state-focused {
  background-color: var(--button-normal-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-contained.dx-button.dx-button-normal.dx-state-disabled {
  background-color: var(--button-normal-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);

  .dx-button-text {
    color: var(--button-normal-color);
  }
}

.dx-button-mode-outlined.dx-button.dx-button-normal.dx-state-disabled {
  .dx-button-text {
    color: var(--button-normal-background-color);
  }
}

.dx-button-mode-text.dx-button.dx-button-normal.dx-state-disabled {
  .dx-button-text {
    color: var(--button-normal-background-color);
  }
}

.dx-button-mode-outlined.dx-button-normal {
  background-color: transparent;
  border-color: var(--button-normal-color);
  color: var(--button-normal-color);
}

.dx-button-mode-outlined.dx-button-normal.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-outlined.dx-button-normal.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-outlined.dx-button-normal.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-text.dx-button-normal {
  background-color: transparent;
  border-color: transparent;
  color: var(--button-normal-color);
}

.dx-button-mode-text.dx-button-normal.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-text.dx-button-normal.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-text.dx-button-normal.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

// --- SUCCESS
.dx-button.dx-button-success {
  background-color: var(--button-success-background-color);
  color: var(--button-success-color);

  .dx-icon {
    color: var(--button-success-color);
  }
}

.dx-button.dx-button-success.dx-state-hover {
  background-color: var(--button-success-background-color);
  opacity: 0.8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button.dx-button-success.dx-state-active {
  background-color: var(--button-success-background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button.dx-button-success.dx-state-focused {
  background-color: var(--button-success-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-contained.dx-button.dx-button-success.dx-state-disabled {
  background-color: var(--button-success-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);

  .dx-button-text {
    color: var(--button-success-color);
  }
}

.dx-button-mode-outlined.dx-button.dx-button-success.dx-state-disabled {
  .dx-button-text {
    color: var(--button-success-background-color);
  }
}

.dx-button-mode-text.dx-button.dx-button-success.dx-state-disabled {
  .dx-button-text {
    color: var(--button-success-background-color);
  }
}

.dx-button-mode-outlined.dx-button-success {
  background-color: transparent;
  border-color: var(--button-success-background-color);
  color: var(--button-success-background-color);

  .dx-icon {
    color: var(--button-success-background-color);
  }
}

.dx-button-mode-outlined.dx-button-success.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-outlined.dx-button-success.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-outlined.dx-button-success.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-text.dx-button-success {
  background-color: transparent;
  border-color: transparent;
  color: var(--button-success-background-color);

  .dx-icon {
    color: var(--button-success-background-color);
  }
}

.dx-button-mode-text.dx-button-success.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-text.dx-button-success.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-text.dx-button-success.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

// --- DEFAULT
.dx-button.dx-button-default {
  background-color: var(--button-default-background-color);
  color: var(--button-default-color);

  .dx-icon {
    color: var(--button-default-color);
  }
}

.dx-button.dx-button-default.dx-state-hover {
  background-color: var(--button-default-background-color);
  opacity: 0.8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button.dx-button-default.dx-state-active {
  background-color: var(--button-default-background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button.dx-button-default.dx-state-focused {
  background-color: var(--button-default-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-outlined.dx-button-default {
  background-color: transparent;
  border-color: var(--button-default-background-color);
  color: var(--button-default-background-color);

  .dx-icon {
    color: var(--button-default-background-color);
  }
}

.dx-button-mode-contained.dx-button.dx-button-default.dx-state-disabled {
  background-color: var(--button-default-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);

  .dx-button-text {
    color: var(--button-default-color);
  }
}

.dx-button-mode-outlined.dx-button.dx-button-default.dx-state-disabled {
  .dx-button-text {
    color: var(--button-default-background-color);
  }
}

.dx-button-mode-text.dx-button.dx-button-default.dx-state-disabled {
  .dx-button-text {
    color: var(--button-default-background-color);
  }
}

.dx-button-mode-outlined.dx-button-default.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-outlined.dx-button-default.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-outlined.dx-button-default.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-text.dx-button-default {
  background-color: transparent;
  border-color: transparent;
  color: var(--button-default-background-color);

  .dx-icon {
    color: var(--button-default-background-color);
  }
}

.dx-button-mode-text.dx-button-default.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-text.dx-button-default.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-text.dx-button-default.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

// --- DANGER
.dx-button.dx-button-danger {
  background-color: var(--button-danger-background-color);
  color: var(--button-danger-color);

  .dx-icon {
    color: var(--button-danger-color);
  }
}

.dx-button.dx-button-danger.dx-state-hover {
  background-color: var(--button-danger-background-color);
  opacity: 0.8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button.dx-button-danger.dx-state-active {
  background-color: var(--button-danger-background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button.dx-button-danger.dx-state-focused {
  background-color: var(--button-danger-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-contained.dx-button.dx-button-danger.dx-state-disabled {
  background-color: var(--button-danger-background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);

  .dx-button-text {
    color: var(--button-danger-color);
  }
}

.dx-button-mode-outlined.dx-button.dx-button-danger.dx-state-disabled {
  .dx-button-text {
    color: var(--button-danger-background-color);
  }
}

.dx-button-mode-text.dx-button.dx-button-danger.dx-state-disabled {
  .dx-button-text {
    color: var(--button-danger-background-color);
  }
}

.dx-button-mode-outlined.dx-button-danger {
  background-color: transparent;
  border-color: var(--button-danger-background-color);
  color: var(--button-danger-background-color);

  .dx-icon {
    color: var(--button-danger-background-color);
  }
}

.dx-button-mode-outlined.dx-button-danger.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-outlined.dx-button-danger.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-outlined.dx-button-danger.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-button-mode-text.dx-button-danger {
  background-color: transparent;
  border-color: transparent;
  color: var(--button-danger-background-color);

  .dx-icon {
    color: var(--button-danger-background-color);
  }
}

.dx-button-mode-text.dx-button-danger.dx-state-hover {
  background-color: transparent;
  opacity: 0.6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}

.dx-button-mode-text.dx-button-danger.dx-state-active {
  background-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.32);
}

.dx-button-mode-text.dx-button-danger.dx-state-focused {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
}

.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
  opacity: 0.5;
}

.dx-button-text {
  text-transform: none;
  font-size: medium !important;
}

.no-background-button {
  background-color: none !important;
}

//--------------

.dx-datagrid-header-panel {
  padding: 0 !important;
}

.dx-datagrid .dx-link {
  color: dx.$base-accent !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.content {
  width: 100%;
  background-color: var(--background-color-white);
  padding-left: 20px;
  padding-right: 20px;
}

.content-space {
  flex: 0 0 1280px;
  background-color: var(--background-color-white);
  border-left: 1px solid var(--border-color-grey);
  border-right: 1px solid var(--border-color-grey);
  padding-left: 20px;
  padding-right: 20px;
}

.container-header-strong {
  font-size: 16px;
}

.color-error {
  color: var(--prime-color-red);
}

.color-success {
  color: var(--prime-color-green);
}

.dx-popup-title {
  background-color: var(--intern-start-card-background-color);
}

.dx-popup-normal {
  background-color: rgb(255, 255, 255) !important;
}

//--------------

.dx-scrollbar-horizontal {
  height: 13px !important;

  .dx-scrollable-scroll {
    height: 13px !important;
  }
}

.dx-scrollbar-vertical {
  width: 13px !important;

  .dx-scrollable-scroll {
    width: 13px !important;
  }
}

.dx-texteditor.dx-editor-outlined {
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  border-image: initial;
  border-radius: 4px;
}

.dx-texteditor.dx-editor-outlined.dx-state-focused {
  box-shadow: none;
}

.dx-texteditor.dx-state-focused {
  border-color: rgba(23, 40, 88);
}

.dx-texteditor.dx-state-hover,
.dx-texteditor.dx-state-hover.dx-state.unfocused {
  border-color: rgba(23, 40, 88, 0.4);
}

.dx-field-item-label-location-top {
  padding: 0;
  font-size: medium !important;
}

.dx-texteditor.dx-editor-outlined .dx-placeholder::before {
  padding-top: 12px;
}

.dx-accordion-item-title::before {
  color: var(--button-default-background-color);
}

// ---- Kalender ----
.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
  border-radius: 0;
  box-shadow: none;
}

.dx-calendar-caption-button.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--prime-color-darkCustom) !important;
}

.dx-calendar-cell.dx-calendar-selected-date span {
  background-color: var(--prime-color-darkCustom);
  color: var(--button-menue-font-color-custom);
  border-radius: 25% !important;
}

.dx-calendar-cell.dx-calendar-today span {
  background-color: var(--prime-color-darkGray);
  color: var(--prime-color-darkCustom);
  border-radius: 25% !important;
}

.dx-calendar-navigator-previous-view,
.dx-calendar-navigator-next-view {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--prime-color-darkCustom) !important;
}

.dx-calendar-navigator-previous-view {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.dx-calendar-navigator-next-view {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date {
  color: var(--button-menue-font-color-custom);
  border-radius: 25% !important;
  -webkit-box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 1000px transparent;
  box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 1000px transparent;

  span {
    color: var(--button-menue-font-color-custom);
    background-color: var(--prime-color-darkCustom);
  }
}

.dx-calendar-cell.dx-calendar-today,
.dx-calendar-cell.dx-calendar-today.dx-calendar-contoured-date {
  color: var(--prime-color-darkCustom);
  border-radius: 25% !important;
  -webkit-box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 1000px transparent;
  box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 1000px transparent;
}

.dx-datebox-calendar.dx-dropdowneditor .dx-dropdowneditor-icon::before,
.dx-datebox-calendar.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon::before {
  content: '';
}

.dx-calendar-navigator {
  background-color: var(--prime-color-darkCustom);

  .dx-button-text {
    color: var(--button-menue-font-color-custom);
  }

  .dx-icon {
    color: var(--button-menue-font-color-custom) !important;
  }
}

.dx-popup-wrapper .dx-overlay-content {
  border: 1px solid #ddd;
  background: #fff;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  border-radius: 6px;
}

// --------------------

.dx-button-mode-contained.dx-button.dx-button-normal.dx-state-disabled {
  background-color: var(--button-normal-color);
}

.padding-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.dx-gallery-nav-button-next::before {
  right: 0 !important;
}

.dx-gallery-nav-button-next.dx-state-hover::after,
.dx-gallery-nav-button-next.dx-state-active::after {
  background-color: var(--prime-color-darkCustom) !important;
  opacity: 0.4;
  right: 0;
}

.dx-gallery-nav-button-prev::before {
  left: 0 !important;
}

.dx-gallery-nav-button-prev.dx-state-hover::after,
.dx-gallery-nav-button-prev.dx-state-active::after {
  background-color: var(--prime-color-darkCustom) !important;
  opacity: 0.4;
  left: 0;
}

.dx-gallery-indicator-item-selected {
  background-color: var(--prime-color-darkCustom) !important;
  opacity: 0.7;
}

.dx-texteditor.dx-editor-filled .dx-placeholder::before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-placeholder::before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 9px 11px 8px;
  font-size: smaller;
}

.dx-menu-base .dx-menu-item .dx-menu-item-content {
  line-height: 20px;
}

.dx-menu-base .dx-icon {
  font-size: 18px;
}

.dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 8px 11px;
}

.dx-show-clear-button .dx-icon-clear,
.dx-invalid.dx-texteditor.dx-show-invalid-badge .dx-texteditor-input-container::after {
  width: 16px;
  height: 16px;
  margin-top: -8px;
}

.dx-invalid.dx-texteditor.dx-show-invalid-badge .dx-texteditor-input-container::after {
  width: 16px;
  height: 16px;
  line-height: 17px;
}

.dx-slider-handle .dx-tooltip-wrapper .dx-overlay-content::before {
  content: '';
  position: absolute;
  width: auto;
  height: auto;
  border-radius: 0 0% 0%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  display: block;
  background-color: #ebebeb !important;
  top: 0;
}
.dx-slider-handle .dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  line-height: normal;
  font-size: 12px;
  max-width: 40px;
  min-width: 28px;
  width: 40px;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
}

.moving-container-add {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: var(--prime-color-green);
  position: absolute;
  transition: all 1.5s ease-in;
  opacity: 1;
  border-radius: 3px;
  font-size: 16px;
}

.move-add {
  left: calc(100% - 100px) !important;
  top: 0 !important;
  opacity: 0;
}

.moving-container-remove {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: var(--prime-color-red);
  position: absolute;
  transition: all 1s ease-in;
  opacity: 0.8;
  border-radius: 3px;
  font-size: 16px;
}

.move-remove {
  right: 20px !important;
  top: 40% !important;
  opacity: 0;
}

//-- dx-icons durch unsere ersetzen

.dx-icon-edit::before {
  font-family: 'CzIconFont';
  content: '\e91a';
}

.dx-icon-trash::before {
  font-family: 'CzIconFont';
  content: '\e91c';
}

.dx-icon-add::before {
  font-family: 'CzIconFont';
  content: '\e900';
}

.dx-icon-help::before {
  font-family: 'CzIconFont';
  content: '\e910';
}

.dx-icon-preferences::before {
  font-family: 'CzIconFont';
  content: '\e919';
}

.dx-icon-find::before {
  font-family: 'CzIconFont';
  content: '\e91e';
}

.dx-icon-clock::before {
  font-family: 'CzIconFont';
  content: '\e92b';
}

.dx-icon-home::before {
  font-family: 'CzIconFont';
  content: '\e911';
}

.dx-icon-export::before {
  font-family: 'CzIconFont';
  content: '\e90c';
}

.dx-icon-event::before {
  font-family: 'CzIconFont';
  content: '\e908';
}

.dx-icon-user::before {
  font-family: 'CzIconFont';
  content: '\e905';
}

.dx-icon-contentlayout::before {
  font-family: 'CzIconFont';
  content: '\e916';
}

.dx-icon-clear::before {
  font-family: 'CzIconFont';
  content: '\e903';
}

.dx-icon-activefolder::before {
  font-family: 'CzIconFont';
  content: '\e90f';
}

.dx-icon-download::before {
  font-family: 'CzIconFont';
  content: '\e90a';
}

.dx-icon-check::before {
  font-family: 'CzIconFont';
  content: '\e918'; /*918 904*/
}

.dx-icon-remove::before {
  font-family: 'CzIconFont';
  content: '\e903'; /*903 924*/
}

.dx-icon-revert::before {
  font-family: 'CzIconFont';
  content: '\e920';
}

.dx-icon-columnchooser::before {
  font-family: 'CzIconFont';
  content: '\e928';
}

.dx-icon-docxfile::before {
  font-family: 'CzIconFont';
  content: '\e923';
}

.dx-icon-close::before {
  font-family: 'CzIconFont';
  content: '\e903'; /*903 924*/
}

.dx-icon-save::before {
  font-family: 'CzIconFont';
  content: '\e904'; /*918 904*/
}

// NEU
.dx-icon-verticalaligntop::before {
  font-family: 'CzIconFont';
  content: '\e935';
}

.dx-icon-chart::before {
  font-family: 'CzIconFont';
  content: '\e92e';
}

.dx-icon-doc::before {
  font-family: 'CzIconFont';
  content: '\e92f';
}

.dx-icon-formula::before {
  font-family: 'CzIconFont';
  content: '\e92d';
}

.dx-icon-food::before {
  font-family: 'CzIconFont';
  content: '\e930';
}

.dx-icon-info::before {
  font-family: 'CzIconFont';
  content: '\e932';
}

.dx-icon-message::before {
  font-family: 'CzIconFont';
  content: '\e913';
}

.dx-icon-calculator::before {
  font-family: 'CzIconFont';
  content: '\e92d';
}

.dx-icon-file::before {
  font-family: 'CzIconFont';
  content: '\e92f';
}

.dx-icon-exportselected::before {
  font-family: 'CzIconFont';
  content: '\e91d';
}

.dx-icon-pdffile::before {
  font-family: 'CzIconFont';
  content: '\e934';
}

.dx-icon-detailslayout::before {
  font-family: 'CzIconFont';
  content: '\e916';
}

.dx-icon-tableproperties::before {
  font-family: 'CzIconFont';
  content: '\e90b';
}

.dx-icon-selectall::before {
  font-family: 'CzIconFont';
  content: '\e92c';
}

.dx-datebox-calender.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon::before {
  font-family: 'CzIconFont' !important;
  content: '\e908';
}

.dx-icon-sortuptext::before {
  font-family: 'CzIconFont' !important;
  content: '\e93b';
}

.dx-icon-sortdowntext::before {
  font-family: 'CzIconFont' !important;
  content: '\e93a';
}

.dx-datagrid.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-asc::before {
  display: inline-block;
  font-family: 'CzIconFont';
  font-style: normal;
  content: '\e93a';
}

.dx-datagrid.dx-context-menu .dx-menu-items-container .dx-icon-context-menu-sort-desc::before {
  display: inline-block;
  font-family: 'CzIconFont';
  font-style: normal;
  content: '\e93b';
}

.dx-icon-card::before {
  font-family: 'CzIconFont' !important;
  content: '\e93c';
}
